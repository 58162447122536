import { NoInfer } from "@reduxjs/toolkit/dist/tsHelpers";
import { ReactElement, ReactNode } from "react";
export const VEHICLES_LOADING = "VEHICLES_LOADING";
export const VEHICLES_FAILURE = "VEHICLES_FAILURE";
export const VEHICLES_SUCCESS = "VEHICLES_SUCCESS";

export type VehicleType = {
  vehicles: Array<Vehicles>;
};

export type Vehicles = {
  id: string;
  obdTimestamp: string;
  values: Array<VehicleData>;
  MCUFirmwareVersion: string;
  AssetNumber: string;
};

export type VehicleData = {
  VIN: string;
  Date: string;
  DeviceName: string;
  OBDValid: boolean;
  OEMBatteryVoltage: number;
  FuelLevel: number;
  RPM: number;
  RSSI: number;
  Odometer: number;
  DriverSeatbeltStatus: number;
  PassengerSeatbeltStatus: number;
  DriverDoorStatus: number;
  PassengerDoorStatus: number;
  BatteryStateOfCharge: number;
  GNSSIsMoving: boolean;
  WheelBasedSpeed: number;
};

export interface VehicleLoading {
  type: typeof VEHICLES_LOADING;
}

export interface VehicleFailure {
  type: typeof VEHICLES_FAILURE;
}

export interface VehicleSuccess {
  type: typeof VEHICLES_SUCCESS;
  payload: Array<Vehicles>;
}

export type VehicleDispatchTypes =
  | VehicleLoading
  | VehicleFailure
  | VehicleSuccess;