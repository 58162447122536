export const METRICS_LOADING = "METRICS_LOADING";
export const METRICS_SUCCESS = "METRICS_SUCCESS";

export type Metrics = {
  id: string;
  MCUVersion: string;
  obdTimestamp: string;
  milesDriven: number | string | null;
  odometer: number | string | null;
  fuelLevel: number | string | null;
  lastDate: number | string | null;
  [key: string]: any;
  driverDrivingWithDoorOpen: number | string | boolean | null;
  driverDrivingWithSeatbeltOff: number | string | boolean | null;
  passengerDrivingWithDoorOpen: number | string | boolean | null;
  passengerDrivingWithSeatbeltOff: number | string | boolean | null;
  RPM: number | string | null;
  notifications: { dtc: string; timestamp: any }[];
};

export interface MetricsLoading {
  type: typeof METRICS_LOADING;
}

export interface MetricsSuccess {
  type: typeof METRICS_SUCCESS;
  payload: Array<Metrics>;
}

export type MetricsDispatchTypes = MetricsLoading | MetricsSuccess;
